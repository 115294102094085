import {http,http_no_aes} from '@/utils/http'

// 报警器相关
// 2.1获取已设置报警监控列表接口
export const getAlarmList = data => http('/System/get_robot_alert',data,'post')
// 2.2系统报警提醒保存启动接口
export const setAlarm = data => http_no_aes('/System/set_robot_alert',data,'post')
// 2.3系统报警提醒删除接口
export const delAlarm = data => http_no_aes('/System/delete_robot_alert',data,'post')

// 获取系统报警器状态接口
export const getAlertState = data => http('/Markets/get_alert_state',data,'post')
