<template>
  <div>
    <!-- 二次确认是否需要配置报警系统 -->
    <el-dialog
      class="g_dialog_messageBox"
      :title="$t('tip.tips')"
      :visible.sync="showTip"
    >
      <div class="alarmDialog">
        <div class="tip">{{ $t("tip.needConfigAlarm") }}</div>
        <el-checkbox v-model="isRemember" true-label="1" false-label="0">{{
          $t("tip.notShowWeek")
        }}</el-checkbox>
      </div>

      <div class="g_dialogBtns">
        <div class="g_btn btn" @click="handleCancel">
          {{ $t("common.back") }}
        </div>
        <div class="g_btn btn submit" @click="handleOpenAlarm">
          {{ $t("common.confirm") }}
        </div>
      </div>
    </el-dialog>

    <!-- 报警系统form弹窗 -->
    <el-dialog
      class="w800"
      :title="$t('alarmSystem.activateAlarm')"
      :visible.sync="showAlarmDialog"
    >
      <div class="alarmFormBox verification">
        <div class="tokenForm">
          <div class="sub">{{ $t("alarmSystem.title1") }}</div>
          <div class="tip">* {{ $t("alarmSystem.optionalTip") }}</div>
          <div class="item">
            <div class="title">{{ $t("alarmSystem.priceThan2") }}</div>
            <div class="text">
              <el-input
                v-model="paramArr[0]"
                @input.native="inputHandler($event, 0)"
                :placeholder="$t('common.value')"
                class="inp"
              />
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("alarmSystem.priceBelow2") }}</div>
            <div class="text">
              <el-input
                v-model="paramArr[1]"
                @input.native="inputHandler($event, 1)"
                :placeholder="$t('common.value')"
                class="inp"
              />
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("alarmSystem.mutation2") }}</div>
            <div class="text">
              <el-input
                v-model="paramArr[5]"
                @input.native="inputHandler($event, 5)"
                :placeholder="$t('common.value')"
                class="inp"
              />
              <div class="company">%</div>
            </div>
          </div>
        </div>
        <div class="assetsForm">
          <div class="sub">{{ $t("alarmSystem.title2") }}</div>
          <div class="tip">* {{ $t("alarmSystem.optionalTip") }}</div>
          <div class="item">
            <div class="title">
              {{ $t("alarmSystem.less2", { token: initYenUnit1||yenUnit1 }) }}
            </div>
            <div class="text">
              <el-input
                v-model="paramArr[2]"
                @input.native="inputHandler($event, 2)"
                :placeholder="$t('common.value')"
                class="inp"
              />
            </div>
          </div>
          <div class="item">
            <div class="title">
              {{ $t("alarmSystem.less2", { token: initYenUnit2||yenUnit2 }) }}
            </div>
            <div class="text">
              <el-input
                v-model="paramArr[3]"
                @input.native="inputHandler($event, 3)"
                :placeholder="$t('common.value')"
                class="inp"
              />
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("alarmSystem.conditions2") }}</div>
            <div class="radioBox radioType">
              <el-radio v-model="paramArr[4]" label="1">{{
                $t("common.yes")
              }}</el-radio>
              <el-radio v-model="paramArr[4]" label="0">{{
                $t("common.no")
              }}</el-radio>
            </div>
          </div>
          <div class="item">
            <div class="title">{{ $t("alarmSystem.colseRobot2") }}</div>
            <div class="radioBox radioType">
              <el-radio v-model="paramArr[6]" label="1">{{
                $t("common.yes")
              }}</el-radio>
              <el-radio v-model="paramArr[6]" label="0">{{
                $t("common.no")
              }}</el-radio>
            </div>
          </div>
        </div>
      </div>
      <div class="foot">
        <!-- 
        报警系统页-属于二级弹窗-有返回
        返回+保存并启动（添加/未启动）+仅保存
       -->
        <template v-if="initType">
          <span class="g_btn btn" @click="handleBack">
            {{ $t("common.back") }}
          </span>
          <span
            v-if="initType == 'add' || alarmState == '0'"
            class="g_btn btn submit"
            @click="handleAlarmConfirm('run')"
          >
            {{ $t("common.saveRun") }}
          </span>
          <span class="g_btn btn submit" @click="handleAlarmConfirm">
            {{ $t("common.save") }}
          </span>
        </template>
        <!-- 取消+启动/停止 -->
        <template v-else>
          <span class="g_btn btn" @click="showAlarmDialog = false">
            {{ $t("common.cancel") }}
          </span>
          <span class="g_btn btn submit" @click="handleAlarmConfirm">{{
            alarmState == 0 ? $t("common.enableNow") : $t("common.stop")
          }}</span>
          <span
            v-if="alarmState == 1"
            class="g_btn btn submit"
            @click="handleAlarmConfirm('run')"
          >
            {{ $t("common.save") }}
          </span>
        </template>
      </div>
      <div class="alarmMainTip">* {{ $t("tip.alarmMainTip") }}</div>
    </el-dialog>
  </div>
</template>

<style scoped="scoped" lang="stylus">
@import 'alarmDialog.styl';
</style>

<script>
import { Loading } from "element-ui";
import { mapGetters } from "vuex";
import { setAlarm, getAlertState } from "@/api/alarm";
export default {
  data() {
    return {
      showTip: false, //是否显示报警系统提示
      isRemember: "0", //是否记住一周
      needGetAlertState: 0, //是否需要获取报警弹窗数据

      showAlarmDialog: false,
      paramArr: [], //弹窗数据
      alarmState: 0,

      initWeb: "",
      initYenUnit1: "",
      initYenUnit2: "",
      initType: "", //add edit
    };
  },
  props: ["web", "yenUnit1", "yenUnit2"],
  computed: {
    ...mapGetters(["account"]),
  },
  methods: {
    // 打开报警确认弹窗 needgetAlertState:是否需要获取报警弹窗数据
    openCheckTip(needgetAlertState, data) {
      this.needGetAlertState = needgetAlertState == 1 ? 1 : 0;
      if (data&&data.web) {
        this.initWeb = data.web || "";
        this.initYenUnit1 = data.yenUnit1 || "";
        this.initYenUnit2 = data.yenUnit2 || "";
      }

      // 没有设置不再提醒或者设置了不再提醒但是时间超过一周
      if (
        !localStorage.getItem("alarmIsRemember") ||
        localStorage.getItem("alarmIsRemember") == 0 ||
        (localStorage.getItem("alarmIsRemember") == 1 &&
          new Date().getTime() - localStorage.getItem("alarmRememberTime") >
            604800000)
      ) {
        this.isRemember = "0";
        this.$nextTick(() => {
          this.showTip = true;
        });
      }
    },
    // 打开报警系统弹窗
    handleOpenAlarm() {
      this.showTip = false;
      // 存储时间和是否需要配置报警系统
      if (this.isRemember == 1) {
        localStorage.setItem("alarmIsRemember", "1");
        localStorage.setItem("alarmRememberTime", new Date().getTime());
      }
      this.needGetAlertState == 1 && this.getAlertState();
      this.openAlarm();
    },
    handleCancel() {
      this.showTip = false;
      if (this.isRemember == 1) {
        localStorage.setItem("alarmIsRemember", "1");
        localStorage.setItem("alarmRememberTime", new Date().getTime());
      }
    },

    // 报警弹窗数据回显-报警系统页面
    initAlarm(data) {
      this.initWeb = data.web || "";
      this.initYenUnit1 = data.yenUnit1 || "";
      this.initYenUnit2 = data.yenUnit2 || "";
      this.paramArr = data.paramArr || [];
      this.paramArr[4] = this.paramArr[4] ? this.paramArr[4] : "0";
      this.paramArr[6] = this.paramArr[6] ? this.paramArr[6] : "0";
      this.alarmState = data.state || 0;
      this.initType = data.type || "";
      this.showAlarmDialog = true;
    },
    // 返回
    handleBack() {
      this.showAlarmDialog = false;
      this.$emit("handleBack");
    },

    // 打开报警弹窗
    openAlarm() {
      this.showAlarmDialog = true;
    },
    // 启用报警系统
    handleAlarmConfirm(isRun) {
      // 只需存在一个参数即可
      var empty = true;
      var inputList = document.querySelectorAll(
        ".verification .el-input__inner"
      );
      try {
        inputList.forEach((item) => {
          if (!!item.value) {
            throw "hasData";
          }
        });
      } catch {
        empty = false;
      }

      if (empty && (!this.paramArr[4] || this.paramArr[4] == "0")) {
        this.$message({
          message: this.$t("tip.dataErr"),
          type: "error",
          center: true,
          offset: 100,
          customClass: "shotMsg",
        });
      } else {
        var web = this.initWeb || this.web;
        var yenUnit1 = this.initYenUnit1 || this.yenUnit1;
        var yenUnit2 = this.initYenUnit2 || this.yenUnit2;

        var currency;
        if (this.web != "opensea") {
          currency = yenUnit1 + "_" + yenUnit2;
        } else {
          currency = yenUnit1;
        }

        var state;
        if (isRun == "run") {
          state = "1";
        } else if (this.initType) {
          // 报警系统页-属于二级弹窗-有返回
          // 启动状态编辑 保持启动状态
          if (this.initType == "edit" && this.alarmState == "1") {
            state = "1";
          } else {
            state = "0";
          }
        } else {
          //取消+启动/停止
          state = this.alarmState == 0 ? 1 : 0;
        }

        const loading = Loading.service({
          text: "Loading",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)",
          customClass: "apploading",
        });

        setAlarm({
          web,
          account: this.account,
          currency,
          param: this.paramArr.join(" "),
          state,
        })
          .then((res) => {
            loading.close();
            // 报警系统页-属于二级弹窗-有返回
            if (this.initType) {
              // 成功
              this.$message({
                message:
                  this.initType == "add"
                    ? this.$t("tip.addSuc")
                    : this.$t("tip.editSuc"),
                type: "success",
                center: true,
                offset: 100,
                customClass: "shotMsg",
              });

              this.showAlarmDialog = false;
              this.$emit("getList");
            }
            // 取消+启动/停止
            else {
              this.$message({
                message:
                  this.alarmState == 0 || isRun == "run"
                    ? this.$t("tip.startSuc")
                    : this.$t("tip.stopSuc"),
                type: "success",
                center: true,
                customClass: "shotMsg",
              });

              this.showAlarmDialog = false;
              this.alarmState =
                isRun == "run" ? 1 : this.alarmState == 0 ? 1 : 0;
              this.$emit("updateState", this.alarmState);
              // this.paramArr = [];
            }
          })
          .catch(() => {
            loading.close();
          });
      }
    },

    // 获取报警弹窗数据
    getAlertState() {
      var currency;
      if (this.web != "opensea") {
        currency =
          (this.initYenUnit1 || this.yenUnit1) +
          "_" +
          (this.initYenUnit2 || this.yenUnit2);
      } else {
        currency = this.initYenUnit1 || this.yenUnit1;
      }
      getAlertState({
        web: this.initWeb || this.web,
        currency,
        account: this.account,
      }).then((res) => {
        this.paramArr = res.param ? res.param.split(" ") : [];
        this.paramArr[4] = this.paramArr[4] ? this.paramArr[4] : "0";
        this.paramArr[6] = this.paramArr[6] ? this.paramArr[6] : "0";

        this.alarmState = res.state || 0;
        this.$emit("updateState", this.alarmState);
      });
    },
    // 正数-paramArr-报警
    inputHandler(event, position) {
      let value = event.target.value.replace(/[^0-9.]/g, "");
      // 避免中文输入法影响
      setTimeout(() => {
        this.$set(this.paramArr, position, value >= 0 ? value : "");
      });
    },
  },
};
</script>